<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="open-apps-dib.list" :count="count"/>
        <form class="tw-flex tw-mb-7" @submit.prevent="submitShortFilter">
            <div class="tw-w-[416px] tw-flex tw-justify-center tw-items-center">
                <div class="tw-flex-grow">
                    <AppDatePicker v-model="shortFilter.created_at_from" size="large" class="application-filter-datepicker" placeholder="common.dateFrom" default-value=""/>
                </div>
                <div class="tw-mx-2.5 tw-font-secondary tw-text-secondary-50">—</div>
                <div class="tw-flex-grow">
                    <AppDatePicker v-model="shortFilter.created_at_to" size="large" class="application-filter-datepicker" placeholder="common.dateTo" default-value=""/>
                </div>
            </div>
            <AppButton class="tw-ml-5 tw-px-6">
                {{ $t("common.apply") }}
            </AppButton>
        </form>

        <OpenApplicationContentItems :filter="filter" :content-attrs="contentAttrs" :content-listeners="contentListeners" @open="openApplication"/>
    </div>
</template>
<script>
import AppButton from "../../components/partials/AppButton";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import {getApplicationDefaultMixin, getApplicationFilterMixin} from "../../mixins/ScriptPage";
import {
    ContentLoader,
    ScriptPage
} from "../../services/ScriptPage";
import AppDatePicker from "../../components/partials/AppDatePicker";
import OpenApplicationContentItems from "../../components/admin/OpenApplicationContentItems";

const defaultFilter = {
    skip: 0,
    limit: 20,
    created_at_from: '',
    created_at_to: ''
};

class DIBContentLoader extends ContentLoader {
    fetch(filterString) {
        return super.fetch('fraud=4&' + filterString);
    }
}

export default {
    name: "ApplicationDIB",
    components: {
        OpenApplicationContentItems,
        AppDatePicker,
        ApplicationHeader,
        AppButton,
    },
    mixins: [
        getApplicationDefaultMixin(new ScriptPage(
            new DIBContentLoader('/applications'),
            defaultFilter,
        )),
        getApplicationFilterMixin(defaultFilter),
    ],
    data() {
        return {
            defaultFilter,
            shortFilter: {
                created_at_from: '',
                created_at_to: ''
            }
        }
    },
    created() {
        this.shortFilter = {
            created_at_from: this.filter.created_at_from,
            created_at_to: this.filter.created_at_to,
        }
    },
    methods: {
        submitShortFilter() {
            if(this.shortFilter.created_at_from) {
                this.page.setFilterValue('created_at_from', this.shortFilter.created_at_from);
            } else {
                this.page.setFilterValue('created_at_from', null);
            }
            if(this.shortFilter.created_at_to) {
                this.page.setFilterValue('created_at_to', this.shortFilter.created_at_to);
            } else {
                this.page.setFilterValue('created_at_to', null);
            }
            this.page.fetch();
        },
        openApplication(id) {
            this.$router.push(`/open-apps-dib/${id}?${this.page.getFilterString()}`);
        },
    }
}

</script>
<style lang="scss">
@import '../../assets/scss/variables';
.application-filter-datepicker {
    .ant-input {
        border-radius: 12px;
        border: 1px solid #E1E1E1;
        background: #EFEFEF;
        height: 48px;
        padding: 13px 13px 13px 17px;

        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: 0.08px;
    }
    .ant-calendar-picker-icon {
        color: #949494;
        font-size: 18px;
        width: 18px;
        height: 18px;
        right: 15px;
        top: 15px;
        margin: 0;
    }
}
</style>
