import {qqLang, ruLang, uzLang} from "../config/lang";

const langsMixin = {
    created() {
        this.$store.dispatch('fetchLangsById')
    },
    computed: {
        langsById() {
            return this.$store.state.langsById;
        },
        langsIcon() {
            let o = {};
            Object.keys(this.langsById).forEach(key => {
                switch(this.langsById[key].code) {
                    case 'qq': o[key] = qqLang; break;
                    case 'ru': o[key] = ruLang; break;
                    default: o[key] = uzLang;
                }
            });
            return o;
        },
        langsTitle() {
            let o = {};
            Object.keys(this.langsById).forEach(key => {
                o[key] = this.langsById[key].name;
            });
            return o;
        }
    }
}
export default langsMixin;
