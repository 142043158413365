<template>
    <ContentItems v-bind="contentAttrs" v-on="contentListeners" #default="{item, idx}" sekeleton :skeletonList="skeletonList">
        <div class="application-item mb-1-a">
            <div class="application-item__header">
                <template v-if="loading">
                    <div class="skeleton" :style="{height: '32px', width: '40%'}"></div>
                </template>
                <template v-else>
                    <div class="application-item__index">{{ (idx + 1) + filter.skip }}</div>
                <div class="application-item__name">{{ item.customer.username || '-' }}</div>
                <ProblemTag class="application-item__tag" type="flexible" :status="item.status"/>
                <div v-if="langsIcon[item.language_id]" class="lang-icon application-item__tag">
                    <img :src="langsIcon[item.language_id]" alt="flag"/>
                </div>
                <div v-if="item.customer_count" class="application-item__rating" :class="'application-item__rating--theme-' + getRating(item.average_rating)">
                    <AppIcon icon="star-full"/>
                    {{ item.average_rating.toFixed(1) }}
                </div>

                <AppButton class="application-item__more" size="small" square theme="primary-outline" @click="$emit('open', item.id)">
                    <AppIcon icon="dots-horizontal"/>
                </AppButton>
                </template>
            </div>

            <div class="application-item__body">
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.phoneNumber") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>
                            {{ item.phone ? item.phone.phone : '-' }}
                        </span>
                    </div>
                </div>
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.type") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ item.type_name }}</span>
                    </div>
                </div>
                <div class="application-item__info application-item__info--large">
                    <div class="application-item__info-name">
                        {{ $t("common.table.theme") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ item.products_name || '-' }}</span>
                    </div>
                </div>
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.channelOfAppeal") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ getSource(item.source_id) || '-' }}</span>
                    </div>
                </div>
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.entity") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ item.customer.is_company && $t("common.yes") || $t("common.no") }}</span>
                    </div>
                </div>
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.operator") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ item.operator ? item.operator.first_name + ' ' + item.operator.last_name : '-' }}</span>
                    </div>
                </div>
                <div class="application-item__info">
                    <div class="application-item__info-name">
                        {{ $t("common.table.date") }}
                    </div>
                    <div class="application-item__info-value">
                        <div v-if="loading" class="skeleton" :style="{height: '20px', width: '60%'}"></div>
                        <span v-else>{{ item.created_at ? formatDateMoment(item.created_at) : '-' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ContentItems>
</template>
<script>
import ContentItems from "./ContentItems";
import ProblemTag from "../partials/ProblemTag";
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
import langsMixin from "../../mixins/langsMixin";
import {getRatingTheme} from "../partials/StarsRating";
import FilterDate from "../../mixins/FilterDate";
export default {
    name: 'OpenApplicationContentItems',
    components: {AppButton, AppIcon, ProblemTag, ContentItems},
    props: {
        contentAttrs: Object,
        contentListeners: Object,
        filter: Object,
        loading: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        FilterDate,
        langsMixin,
    ],

    methods: {
        getRating(rating) {
            return getRatingTheme(rating);
        },
        getSource(sourceId) {
            let sourceItem = this.sources?.find(source => source.id === sourceId);
            if(!sourceItem) {
                sourceItem = this.sources.find(source => source.slug === 'call-center');
            }
            return sourceItem?.name;
        },
    },

    computed: {
        sources() {
            return this.$store.state.incomingClientSources.map(({id, name}) => ({id, value: +id, name}));
        },

        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    skeleton: true,
                })
            }
            return list
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.application-item {
    $self: &;
    padding: 18px 32px 21px;
    background: #FFFFFF;
    border-radius: 12px;
    font-family: $font-secondary;
    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 14px;
    }
    &__index {
        margin-right: 20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
    }
    &__name {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-transform: uppercase;
        margin-right: 20px;
    }
    &__rating {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        svg {
            margin-right: 6px;
            width: 14px;
            height: 14px;
            color: #00CC56;
        }
        &--theme-danger svg {
            color: #FF4921;
        }
        &--theme-warning svg {
            color: #F2B600;
        }
    }
    &__more {
        margin-left: auto;
    }
    &__body {
        display: flex;
        align-items: flex-start;
    }
    &__info {
        display: flex;
        flex-direction: column;
        width: max(10vw, 132px);
        &--large {
            width: 0;
            flex-grow: 1;
            margin-right: 5vw;
        }
        &:last-child {
            width: 132px;
            flex-shrink: 0;
        }
    }
    &__info-name {
        margin-bottom: 8px;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #949494;
        width: 100%;
    }
    &__info-value {
        color: #212121;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    &__tag {
        margin-top: -3px;
        margin-right: 12px;
    }
    & + & {
        margin-top: 4px;
    }
    @media(max-width: 1890px) {
        &__info-name {
            padding-right: 6.6vw !important;
        }
    }
    @media(max-width: 1710px) {
        &__info-name {
            padding-right: 5.6vw !important;
        }
    }
    @media(max-width: 1500px) {
        &__info-name {
            padding-right: 0 !important;
        }
        &__info-value {
            width: auto;
        }
    }
}
</style>
